import { useMemo, useState } from "react";
import styled, { css, up } from "@xstyled/styled-components";
import {
  useLocation,
  NavLink,
  Link as RouterLink,
  matchPath,
  useMatch,
} from "react-router-dom";

import { Home, Jobs, Mail, Account } from "./icons";

import {
  Text,
  Dropdown,
  DropdownItem,
  Clickable,
  WttjLogo,
} from "@otta/design";
import { pxToRem, modularScale, palette } from "@otta/design-tokens";
import { useQuery } from "@otta/search/apollo";
import { NavBarStatus, NavBarStatusDocument } from "@otta/search/schema";
import { NAV_HEIGHT } from "@otta/search/globalConstants";

const Header = styled.header`
  background: ${palette.brand.white};
  padding: 0 md;
  z-index: 10;

  ${up(
    "tablet",
    css`
      &[data-sticky="true"] {
        position: sticky;
        top: 0;
      }
    `
  )}
`;

const StyledDropdownItem = styled(DropdownItem)`
  padding: 0;
`;

const DropdownLink = styled(NavLink)`
  text-decoration: none;
  color: black;
  padding: md lg;
  display: block;
  text-align: right;
`;

const LogoContainer = styled.div`
  margin-top: ${pxToRem(9)};
`;

const StyledWttjLogo = styled(WttjLogo)`
  height: ${pxToRem(32)};
`;

const Nav = styled.nav<{ alignLeftOnMobile: boolean }>(
  ({ alignLeftOnMobile }) => css`
    margin: 0 auto;
    max-width: ${pxToRem(1198)};
    min-height: ${pxToRem(NAV_HEIGHT)};

    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 350px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    ${up(
      "tablet",
      css`
        flex-direction: row;
        justify-content: ${alignLeftOnMobile ? "space-between" : "center"};
      `
    )}
  `
);

const InboxLinkWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const LinkWrapper = styled.div<{ hide: boolean }>`
  display: ${({ hide }) => (hide ? "none" : "flex")};
`;

const Links = styled.ul<{ $icons?: boolean }>`
  display: flex;
  justify-content: flex-end;
  padding-top: ${({ $icons }) => ($icons ? pxToRem(10) : modularScale(1))};

  ${up(
    "tablet",
    css`
      flex: 1 1 0;
    `
  )}
`;

const Link = styled.li<{ $loggedIn?: boolean }>`
  display: flex;
  -webkit-tap-highlight-color: transparent;
  margin: 0 sm;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${({ $loggedIn }) =>
    $loggedIn &&
    css`
      height: ${pxToRem(48)};
    `};

  ${up(
    "tablet",
    css`
      margin: 0 md;
    `
  )}
  &:last-of-type {
    margin-right: 0;
  }

  &:first-of-type {
    margin-left: 0;
  }
`;

const StyledLink = styled.a<{
  $icons?: boolean;
  $active?: boolean;
  $loggedIn?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: black;
  text-decoration: none;
  border-bottom: 5px solid transparent;
  ${({ $active }) =>
    $active &&
    css`
      border-color: yellow-500 !important;
    `};
  gap: 3;

  padding-bottom: ${({ $icons }) => ($icons ? pxToRem(2) : modularScale(-5))};

  &:hover {
    border-color: gray-100;
  }

  &.active {
    border-color: yellow-500 !important;
  }

  ${({ $loggedIn }) =>
    $loggedIn &&
    css`
      width: ${pxToRem(48)};
    `};
`;

const Dot = styled.span`
  background-color: red-600;
  color: white;
  border-radius: 50%;

  width: ${modularScale(1)};
  height: ${modularScale(1)};

  position: absolute;

  top: ${modularScale(-5)};
  left: ${modularScale(-4)};

  display: flex;
  align-items: center;
  justify-content: center;
`;

// https://stackoverflow.com/a/20249560 - we use an invisible bold element to pre-set the width so there's no jump when the text weight changes
const StyledText = styled(Text)`
  text-align: center;
  &:after {
    display: block;
    content: attr(data-title);
    font-weight: 600;
    height: 1px;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
  }
`;

export function NavBar(): React.ReactElement | null {
  const location = useLocation();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const onboarding = matchPath(location.pathname, "/initial-preferences");
  const publicApplication = matchPath(location.pathname, "/apply/sign-up");

  const hideLinks = !!(location.state as { onboard?: boolean } | undefined)
    ?.onboard;

  const { data, loading } = useQuery(NavBarStatusDocument, { ssr: true });

  const isRecruiter = data?.me?.__typename === "CurrentCompanyRecruiter";

  const candidateData =
    data?.me?.__typename === "CurrentAdmin" ||
    data?.me?.__typename === "CurrentCandidate"
      ? data?.me
      : null;

  const { id: userId, conversations = [] } = candidateData ?? {};

  const totalUnread = useMemo(() => {
    const unreadMessage = (conversation: NavBarStatus.Conversations) =>
      conversation.latestMessage?.read !== true &&
      conversation.latestMessage?.sender.id !== userId;

    const unreadSourcingMessage = (conversation: NavBarStatus.Conversations) =>
      conversation.sourced && conversation.hasAcceptedRequest === null;

    const unreadConversations = conversations.filter(
      conversation =>
        unreadMessage(conversation) || unreadSourcingMessage(conversation)
    );

    return unreadConversations.length;
  }, [userId, conversations]);

  const highlightYou =
    location.pathname.includes("/account-settings") ||
    location.pathname.includes("/profile") ||
    location.pathname.includes("/preferences") ||
    dropdownOpen;

  const isHeaderSticky = useMatch("/jobs/:jobId/apply");

  return (
    <Header data-sticky={!!isHeaderSticky}>
      <Nav alignLeftOnMobile={!onboarding || hideLinks}>
        <LogoContainer>
          <RouterLink to="/">
            <StyledWttjLogo />
          </RouterLink>
        </LogoContainer>

        {!loading && data?.me && (
          <Links $icons={!isRecruiter}>
            {isRecruiter ? (
              <Link>
                <StyledLink href={`https://hire.welcometothejungle.com`}>
                  Hire
                </StyledLink>
              </Link>
            ) : (
              !onboarding && (
                <LinkWrapper hide={hideLinks}>
                  <LinkComponent to="/" text="Home" icon={Home} />
                  <LinkComponent to="/jobs" text="Jobs" icon={Jobs} />
                  <Link $loggedIn>
                    <StyledLink $icons $loggedIn as={NavLink} to="/messaging">
                      {({ isActive }: { isActive: boolean }) => (
                        <>
                          <InboxLinkWrapper>
                            <Mail
                              height="auto"
                              width={modularScale(1.25)}
                              active={isActive}
                            />
                            {totalUnread > 0 && (
                              <Dot data-testid="unread-message-dot">
                                <Text bold size={-1}>
                                  {totalUnread}
                                </Text>
                              </Dot>
                            )}
                          </InboxLinkWrapper>
                          <StyledText
                            data-title="Inbox"
                            size={-1}
                            bold={isActive}
                          >
                            Inbox
                          </StyledText>
                        </>
                      )}
                    </StyledLink>
                  </Link>
                  <Link $loggedIn>
                    <Dropdown
                      open={dropdownOpen}
                      onClick={setDropdownOpen}
                      placement="bottom"
                      align="end"
                      toggler={
                        <StyledLink
                          $icons
                          $loggedIn
                          $active={highlightYou}
                          as={Clickable}
                        >
                          <Account
                            width={modularScale(1.25)}
                            height={modularScale(1.25)}
                            active={highlightYou}
                          />
                          <StyledText
                            data-title="You"
                            size={-1}
                            bold={highlightYou}
                          >
                            You
                          </StyledText>
                        </StyledLink>
                      }
                    >
                      <StyledDropdownItem
                        onClick={() => setDropdownOpen(false)}
                        role="menuitem"
                      >
                        <DropdownLink to="/profile">Profile</DropdownLink>
                      </StyledDropdownItem>
                      <StyledDropdownItem
                        onClick={() => setDropdownOpen(false)}
                        role="menuitem"
                      >
                        <DropdownLink to="/preferences">
                          Search preferences
                        </DropdownLink>
                      </StyledDropdownItem>
                      <StyledDropdownItem
                        onClick={() => setDropdownOpen(false)}
                        role="menuitem"
                      >
                        <DropdownLink to="/account-settings">
                          Account settings
                        </DropdownLink>
                      </StyledDropdownItem>
                      <StyledDropdownItem
                        onClick={() => setDropdownOpen(false)}
                        role="menuitem"
                      >
                        <DropdownLink
                          to="/logout"
                          style={{
                            color: palette.brand.red,
                          }}
                        >
                          Log out
                        </DropdownLink>
                      </StyledDropdownItem>
                    </Dropdown>
                  </Link>
                </LinkWrapper>
              )
            )}
          </Links>
        )}
        {!loading && !data?.me && !isRecruiter && (
          <Links>
            <Link>
              <StyledLink as={NavLink} to="/login">
                Sign in
              </StyledLink>
            </Link>
            {!onboarding && !publicApplication && (
              <>
                <Link>
                  <StyledLink as={NavLink} to="/sign-up">
                    Sign up
                  </StyledLink>
                </Link>
                <Link>
                  <StyledLink href="https://blog.otta.com">Blog</StyledLink>
                </Link>
              </>
            )}
          </Links>
        )}
      </Nav>
    </Header>
  );
}

function LinkComponent({
  text,
  to,
  icon: Icon,
}: {
  text: string;
  to: string;
  icon: React.FC<{ width: string; height: string; active: boolean }>;
}) {
  return (
    <Link $loggedIn>
      <StyledLink $icons $loggedIn as={NavLink} to={to}>
        {({ isActive }: { isActive: boolean }) => (
          <>
            <Icon width={modularScale(1.25)} height="auto" active={isActive} />
            <StyledText data-title={text} bold={isActive} size={-1}>
              {text}
            </StyledText>
          </>
        )}
      </StyledLink>
    </Link>
  );
}
